<template>
  <div class="flex flex-col" :class="wrapperClasses">
    <div class="w-full flex justify-between items-center">
      <label for="" :class="labelClasses">{{ label }}</label>
      <p v-if="required" class="text-xs text-red-500">*required</p>
    </div>
    <input
      :type="inputType"
      :class="state.getInputClass()"
      :placeholder="placeholder"
      v-maska="mask"
      @focus="state.focused = true"
      @blur="state.focused = false"
      @input="$emit('textChange', $event.target.value)"
      :value="modelValue"
      :pattern="pattern"
    />
  </div>
</template>

<script>
import { reactive } from "@vue/reactivity";
export default {
  props: {
    modelValue: {
      type: [String, Number],
      required: false,
      default: "",
    },
    wrapperClasses: {
      type: String,
      required: false,
      default: "",
    },
    labelClasses: {
      type: String,
      required: false,
      default: "text-gray-700 text-sm pb-1",
    },
    inputClasses: {
      type: String,
      required: false,
      default: "bg-white rounded px-4 py-2 border-1 bg-gray-50",
    },
    focusClasses: {
      type: String,
      required: false,
      default: "rounded px-4 py-2 border-1 border-secondary bg-secondary-light",
    },
    label: {
      type: String,
      required: true,
      default: "Label",
    },
    placeholder: {
      type: String,
      required: true,
      default: "Please enter your text",
    },
    mask: {
      type: [String, Array],
      required: false,
      default: "",
    },
    pattern: {
      type: String,
      required: false,
      default: "",
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    inputType: {
      type: String,
      required: false,
      default: "text",
    },
  },
  emits: ["textChange"],
  setup(props) {
    const state = reactive({
      focused: false,
      getInputClass: () => {
        const activeClass = props.modelValue ? "bg-blue-50" : "";
        return state.focused
          ? `${props.focusClasses} ${activeClass}`
          : `${props.inputClasses} ${activeClass}`;
      },
    });

    return {
      state,
    };
  },
};
</script>

<style scoped>
input[type="text"] {
  /* Remove First */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input::-webkit-input-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
input::-moz-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
input::-ms-input-placeholder {
  font-weight: 300;
  color: theme("colors.gray.500");
}
</style>
